import {InjectionToken} from '@angular/core';
import {ConstantService} from "@worxs/services/constant.service";

export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>('environment');

export type Environment = {
  googleTagManagerId: string | null;
  production: boolean;
  apiUrl: string;
  shopName: Extract<keyof ConstantService['constants']['shops'], string>;

  arbejdsliv: {
    adminFirmId: number;
    cmsUrl: string;
  }
  worxs: {
    cmsUrl: string;
  }
}
